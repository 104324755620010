html {
  --mat-table-header-headline-size: 17px;
  --mat-standard-button-toggle-selected-state-background-color: var(
    --order-primary-100
  );
  --mat-standard-button-toggle-selected-state-text-color: var(
    --order-primary-500
  );
}

.mat-mdc-form-field .mat-mdc-icon-button {
  --mdc-icon-button-icon-size: 20px;

  .material-icons {
    font-size: 20px;
    line-height: 24px;
  }
}

.mat-mdc-form-field .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 36px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 6px;
  line-height: 24px;
}

.mat-mdc-form-field-icon-suffix > .mat-icon {
  cursor: pointer;
}

.mat-mdc-form-field .mat-mdc-icon-button svg,
.mat-mdc-form-field .mat-mdc-icon-button img {
  height: 24px;
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 52px;
}

.font-size-150 {
  .mat-mdc-button.mat-mdc-button-base,
  .mat-mdc-raised-button.mat-mdc-button-base,
  .mat-mdc-unelevated-button.mat-mdc-button-base,
  .mat-mdc-outlined-button.mat-mdc-button-base {
    height: 52px;
  }
  .mat-mdc-button {
    --mdc-typography-button-font-size: 1.125em;
  }
}

.mat-mdc-paginator {
  --mdc-typography-caption-font-size: 14px;
}

.mat-mdc-paginator .mat-mdc-select-value {
  font-size: 14px;
}

.mat-typography .mat-mdc-dialog-container .mdc-dialog__content *:not(mat-icon) {
  font-family: $font;
}

.mat-typography .mat-mdc-dialog-container .mdc-dialog__content {
  --mdc-dialog-supporting-text-color: var(--order-primary-300-contrast);
  --mdc-dialog-supporting-text-font: mat.font-family(
    $custom-typography,
    'body-2'
  );
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  --mdc-typography-caption-font-weight: 400;
  --mdc-typography-caption-font-size: 15px;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
  padding: 0 !important;
}

.mat-primary.mat-mdc-unelevated-button:not(:disabled) {
  --mdc-filled-button-label-text-color: #fff;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-selected-checkmark-color: #fff;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #fff;
}

.mat-mdc-form-field .mat-icon[matprefix] {
  width: 20px;
  height: 20px;
  font-size: 20px;
}

input.public-link {
  font-family: $font;
}

.mat-mdc-raised-button:not(:disabled) {
  --mdc-protected-button-label-text-color: #fff;
}

.mdc-button.win-button,
.mdc-fab.mat-mdc-fab:not(:disabled) {
  color: white;
  background: var(--order-primary-500);

  &[disabled='true'] {
    background-color: #eee;
  }

  &.warn {
    background: var(--order-warn-500);
  }
}

.mdc-fab.mat-mdc-fab {
  height: 50px;
  font-weight: 700;
  font-size: 1.125em;
}

.mdc-button.win-button-warning {
  color: var(--order-warn-500);
  background: var(--order-warn-50);
}

.mdc-button.win-button-light {
  padding: 0 16px;
  color: black;
  background: var(--order-primary-100);
}

.mdc-button.win-button-gray {
  padding: 0 16px;
  color: black;
  background: #eee;
}

.mdc-button.win-button {
  --mdc-text-button-label-text-color: #fff;
}

.mat-mdc-table .mat-mdc-row:not(.deleting):hover,
.mat-mdc-table .mat-mdc-footer-row:hover {
  background-color: var(--order-primary-50) !important;
}

.mdc-button.win-button,
.mdc-button.win-button-gray,
.mdc-button.win-button-light {
  border-radius: 0 !important;
}

// Chips
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-selected,
.mat-mdc-standard-chip.mat-primary.mat-mdc-chip-highlighted,
.mat-mdc-chip-selected {
  --mdc-chip-elevated-container-color: transparent;
  --mdc-chip-elevated-container-color: var(--order-primary-100);
  --mdc-chip-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-chip-with-icon-selected-icon-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-chip-set {
  .mat-mdc-chip-option:not(.mdc-evolution-chip--disabled) {
    background-color: transparent;

    --mdc-chip-selected-label-text-color: rgba(0, 0, 0, 0.87);
    --mdc-chip-elevated-selected-container-color: var(--order-primary-100);
  }

  .chip-select.mat-mdc-chip-option:not(.mdc-evolution-chip--disabled),
  .mat-mdc-chip-option.mat-mdc-chip-selected,
  .mat-mdc-chip-option[aria-selected='true'] {
    background-color: var(--order-primary-100);
    color: var(--order-primary-500);
  }

  .mat-mdc-chip-option {
    border: 1px solid var(--order-primary-100);

    &:not(.mat-mdc-chip-disabled).mat-mdc-standard-chip
      .mdc-evolution-chip__action--primary {
      cursor: pointer;
    }
  }

  .chip-select,
  .mat-mdc-chip-selected {
    box-shadow: 0 1px 3px 0px var(--order-primary-50);

    .mdc-evolution-chip__text-label {
      color: var(--order-primary-500);
    }
  }
}

// Snackbar
.mat-mdc-snack-bar-action > button {
  color: var(--order-primary-500);
}

// OTP input
ngx-otp-input .otp-input-box {
  padding: 0 10px;
}
ngx-otp-input .ngx-otp-input-box {
  font-family: $font;
}

ngx-otp-input .otp-input-box input.otp-input {
  width: 100%;
  height: 4.375em;
  color: inherit;
  text-align: center;
  font-size: inherit;
  border: 1px solid #ddd;
  border-radius: 5px;
  -webkit-appearance: none;
  appearance: none;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  outline: none;
}

ngx-otp-input .otp-input-box input.otp-input:focus {
  box-shadow: 0px 1px 5px #ddd;
}

// Fullcalendar
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  border: none;
  margin-right: 5px;
  color: black;
  background-color: var(--order-primary-100);
}

.fc-direction-ltr .fc-toolbar > * > :not(:first-child) {
  background: #ededed !important;
  color: black !important;
  opacity: 0.75 !important;
  border: none;
  margin-left: 0.75em;
}

// Datepicker
.mat-calendar-body-cell.selected {
  background: var(--order-primary-500);
  border-radius: 50%;
  border: 2px solid var(--mat-datepicker-calendar-container-background-color);

  .mat-calendar-body-cell-content {
    color: var(--order-primary-500-contrast);
  }
}

.mat-mdc-form-field-icon-suffix .mat-datepicker-toggle {
  color: var(--mat-datepicker-calendar-date-text-color);
}

// Swiper
:root {
  --swiper-navigation-size: 30px;
  --swiper-navigation-sides-offset: 0;
  --swiper-pagination-bottom: 0;
  --swiper-navigation-color: #8f96a3;
}

// Density settings
.field-density-1 {
  @include mat.form-field-density(-1);
}

.field-density-3 {
  @include mat.form-field-density(-3);
}

.field-density-5 {
  @include mat.form-field-density(-5);
}

.button-toggle-density-1 {
  @include mat.button-toggle-density(-1);
}

.button-toggle-density-3 {
  @include mat.button-toggle-density(-3);
}

.button-density-1 {
  @include mat.button-density(-1);
}

.button-density-3 {
  @include mat.button-density(-3);

  .mdc-button {
    font-weight: 400;
  }
}

.icon-button-density-1 {
  @include mat.icon-button-density(-1);
  @include mat.icon-density(-1);
}

.icon-button-density-3 {
  @include mat.icon-button-density(-3);

  .mat-icon {
    width: 20px;
    font-size: 20px;
    line-height: 24px;
  }
}
