@use '@angular/material' as mat;

@include mat.elevation-classes();
@include mat.app-background();

@import './menutech-theme-palette.scss';

$primary: mat.m2-define-palette($menutech-primary, 500, 600);
$accent: mat.m2-define-palette($menutech-accent, A200, A100, A400);
$warn: mat.m2-define-palette($menutech-warn);
$vip: mat.m2-define-palette($menutech-vip);

$font: 'Dubai', 'Roboto', 'Segue UI', Helvetica, sans-serif;
$custom-typography: mat.m2-define-typography-config(
  $font-family: $font,
  $headline-5: mat.m2-define-typography-level(38px, 42px, 700, $font, 0),
  $headline-6: mat.m2-define-typography-level(27px, 32px, 700, $font, 0),
  $subtitle-1: mat.m2-define-typography-level(22px, 26px, 500, $font, 0),
  $subtitle-2: mat.m2-define-typography-level(19px, 22px, 500, $font, 0),
  $body-1: mat.m2-define-typography-level(16px, 24px, 500, $font, 0),
  $body-2: mat.m2-define-typography-level(16px, 24px, 400, $font, 0),
  $caption: mat.m2-define-typography-level(16px, 22px, 300, $font, 0),
  $button: mat.m2-define-typography-level(15px, 15px, 500, $font, 0),
);

$light-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary,
      accent: $accent,
      warn: $warn,
    ),
    density: -1,
  )
);
// $dark-theme: mat.define-dark-theme(
//   (
//     color: (
//       primary: $primary,
//       accent: $accent,
//       warn: $warn,
//     ),
//   )
// );

// Apply the light theme by default
@include mat.all-component-typographies($custom-typography);
@include mat.all-component-themes($light-theme);

// Apply the dark theme only when the user prefers dark themes.
@media (prefers-color-scheme: dark) {
  // Use the `-color` mixins to only apply color styles without reapplying the same
  // typography and density styles.
  // @include mat.core-theme($dark-theme);
  // @include mat.button-theme($dark-theme);
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

@import '../mixins.scss';
@import '../typography-rules.scss';
@import '../component-rules.scss';
