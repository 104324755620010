@function customTypography($scale) {
  $result: mat.m2-define-typography-config(
    $headline-5: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'headline-5'),
        $scale * mat.m2-line-height($custom-typography, 'headline-5'),
        mat.m2-font-weight($custom-typography, 'headline-5'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'headline-5')
      ),
    $headline-6: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'headline-6'),
        $scale * mat.m2-line-height($custom-typography, 'headline-6'),
        mat.m2-font-weight($custom-typography, 'headline-6'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'headline-6')
      ),
    $subtitle-1: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'subtitle-1'),
        $scale * mat.m2-line-height($custom-typography, 'subtitle-1'),
        mat.m2-font-weight($custom-typography, 'subtitle-1'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'subtitle-1')
      ),
    $subtitle-2: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'subtitle-2'),
        $scale * mat.m2-line-height($custom-typography, 'subtitle-2'),
        mat.m2-font-weight($custom-typography, 'subtitle-2'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'subtitle-2')
      ),
    $body-1: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'body-1'),
        $scale * mat.m2-line-height($custom-typography, 'body-1'),
        mat.m2-font-weight($custom-typography, 'body-1'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'body-1')
      ),
    $body-2: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'body-2'),
        $scale * mat.m2-line-height($custom-typography, 'body-2'),
        mat.m2-font-weight($custom-typography, 'body-2'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'body-2')
      ),
    $caption: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'caption'),
        $scale * mat.m2-line-height($custom-typography, 'caption'),
        mat.m2-font-weight($custom-typography, 'caption'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'caption')
      ),
    $button: mat.m2-define-typography-level(
        $scale * mat.m2-font-size($custom-typography, 'button'),
        $scale * mat.m2-line-height($custom-typography, 'button'),
        mat.m2-font-weight($custom-typography, 'button'),
        $font,
        mat.m2-letter-spacing($custom-typography, 'button')
      ),
  );
  @return $result;
}

.m2-font-size-125 {
  @include mat.all-component-typographies(customTypography(1.25));
}

.m2-font-size-150 {
  @include mat.all-component-typographies(customTypography(1.5));
}

:root {
  --mdc-icon-button-state-layer-size: 32px;
  --mdc-icon-button-icon-size: 18px;
  --mdc-typography-button-letter-spacing: mat.m2-letter-spacing(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-family: mat.font-family(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-size: mat.m2-font-size(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-size: mat.m2-line-height(
    $custom-typography,
    'button'
  );
  --mdc-typography-button-font-size: mat.m2-font-weight(
    $custom-typography,
    'button'
  );
  --mdc-typography-subtitle2-font-size: mat.m2-font-size(
    $custom-typography,
    'body-2'
  );
}

$embeddedFontSize: 20px;
$tooltipFontSize: 16px;

$baseMenuWidth: 320px;
$baseMenuHeight: 120px;

.win-tooltip {
  font-size: $tooltipFontSize;
}

.m2-font-size-125 {
  // Override the typography in the core CSS.
  .mat-mdc-card-title {
    line-height: 34px;
    margin-bottom: 20px;
  }

  win-simple-login {
    .mat-mdc-card {
      width: 325px !important;
      max-width: none !important;
    }
  }
  .dish-wrapper,
  .section-name,
  .allergen-name {
    font-size: $embeddedFontSize * 1.5;
    line-height: normal;
  }

  .mdc-button {
    white-space: pre-wrap;
  }

  .name {
    margin-top: 25px !important;
  }
  .mat-mdc-card-subitle {
    line-height: 24px;
  }

  .win-tooltip {
    font-size: $tooltipFontSize * 1.5;
    padding: 6px * 1.5 8px * 1.5;
  }

  win-menu {
    .wrapper {
      width: $baseMenuWidth * 1.5;
      height: $baseMenuHeight * 1.5;
    }
  }
}

.m2-font-size-150 {
  // Override the typography in the core CSS.
  .mat-mdc-card-title {
    line-height: 48px;
    margin-bottom: 30px;
  }

  win-simple-login {
    .mat-mdc-card {
      width: 350px !important;
      max-width: none !important;
    }
  }

  .dish-wrapper,
  .section-name,
  .allergen-name {
    font-size: $embeddedFontSize * 1.75;
    line-height: normal;
  }
  .mdc-button {
    white-space: pre-wrap;
  }

  .name {
    margin-top: 25px !important;
  }

  .mat-mdc-card-subitle {
    line-height: 32px;
  }
  .win-tooltip {
    font-size: $tooltipFontSize * 1.75;
    padding: 6px * 1.75 8px * 1.75;
  }
  win-menu {
    .wrapper {
      width: $baseMenuWidth * 1.75;
      height: $baseMenuHeight * 1.75;
    }
  }
}
