@font-face {
  font-family: 'Dubai';
  src:
    url('/fonts/dubai-light.woff2') format('woff2'),
    url('/fonts/dubai-light.woff') format('woff'),
    url('/fonts/dubai-light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai';
  src:
    url('/fonts/dubai-regular.woff2') format('woff2'),
    url('/fonts/dubai-regular.woff') format('woff'),
    url('/fonts/dubai-regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai';
  src:
    url('/fonts/dubai-medium.woff2') format('woff2'),
    url('/fonts/dubai-medium.woff') format('woff'),
    url('/fonts/dubai-medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Dubai';
  src:
    url('/fonts/dubai-bold.woff2') format('woff2'),
    url('/fonts/dubai-bold.woff') format('woff'),
    url('/fonts/dubai-bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'menutech';
  src:
    url('/fonts/menutech-regular.woff2') format('woff2'),
    url('/fonts/menutech-regular.woff') format('woff'),
    url('/fonts/menutech-regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  // font-size: 16px;
  height: 100%;
  min-width: 320px;
  margin: 0;
  padding: 0;
  -webkit-overflow-scrolling: touch !important;
}

body {
  margin: 0;
}

router-outlet + * {
  flex: 1;
  display: flex;
}

a {
  color: var(--order-primary-500);
  cursor: pointer;
}

.fill-parent {
  width: 100%;
  height: 100%;
  font-size: 18px;
  letter-spacing: 0.3px;

  .mat-icon {
    position: relative;
    top: -2px;
  }

  &[disabled] {
    background-color: #e0e0e0;
  }
}

.success-snack {
  min-width: auto !important;
  background-color: #daf8f1;
  color: #000;

  .mat-mdc-simple-snack-bar {
    justify-content: center;
  }
}

.vip-color {
  color: var(--order-vip-500);
}

.success-gradient {
  background-image: linear-gradient(
    to top right,
    var(--order-primary-900),
    var(--order-primary-500)
  );
}

.success-green-gradient {
  background-image: linear-gradient(
    to top right,
    var(--order-accent-900),
    var(--order-accent-500)
  );
}

.main .menu .dish-line[data-desc='true'] {
  padding-right: 40px;

  &::before {
    content: 'comment';
    font-family: 'Material Symbols Outlined';
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 18px;
    font-weight: 450;
  }
}

.mat-focused .mat-mdc-form-field-required-marker {
  color: unset;
}

win-select-orders {
  .show-hidden {
    .section-line[data-admin='true'],
    .section-line[data-vip='true'],
    .dish-line[data-admin='true'],
    .dish-line[data-vip='true'],
    .dish-line[data-diet-restriction='true'] {
      display: unset !important;
    }
  }

  .show-vip {
    .dish-line[data-vip='true'] {
      display: unset !important;
    }
  }

  .search-hidden.dish-line[data-orderable],
  .search-hidden.section-line {
    display: none !important;
  }

  .menu-item-disable.dish-line[data-orderable],
  .menu-item-disable.section-line {
    cursor: default;
    display: unset !important;
    opacity: 0.5;
  }

  .glut [data-allergen='glut'],
  .crus [data-allergen='crus'],
  .egg [data-allergen='egg'],
  .fish [data-allergen='fish'],
  .pea [data-allergen='pea'],
  .soy [data-allergen='soy'],
  .milk [data-allergen='milk'],
  .nut [data-allergen='nut'],
  .cel [data-allergen='cel'],
  .must [data-allergen='must'],
  .ses [data-allergen='ses'],
  .suf [data-allergen='suf'],
  .lup [data-allergen='lup'],
  .mol [data-allergen='mol'] {
    color: red !important;
  }
}

win-confirm-orders {
  .repeat-menu-order.highlighted .trigger-button .mat-icon {
    color: var(--order-primary-500) !important;
  }
}

.download-button-text {
  span {
    font-weight: 350;
  }
}

.hidden {
  visibility: hidden;
}

ngx-mat-timepicker-dialog .mat-mdc-mini-fab {
  box-shadow: none !important;
}

.font-size-panel {
  min-width: 109px;
  transform: translateX(-35px) !important;
}

.panel-above {
  position: absolute !important;
  bottom: calc(100% + 54px) !important;
}

@media only screen and (max-width: 500px) {
  .font-size-panel {
    transform: translateX(-45px) !important;
  }
}
